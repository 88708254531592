.time-select-dropdown {
    display: none;
    position: absolute;
    top: 100%;
    width: 100%;
    left: 0;
    height: 250px;
    overflow: scroll;
}

.time-select {
    position: relative;
}

.time-select-dropdown-item {
    background-color: grey;
    border-bottom: 1px solid #5d5d5d;
}

.time-select-dropdown-item:hover {
    background-color: #888888;
}

.time-select-dropdown.active {
    display: block;
}
