.title-bar {
    height: 45px;
    font-size: 28px;
    margin: 20px 0;
    padding-left: 8px;
    text-align: left;
}

.title-bar .add-icon {
    float: right;
}